.app {
  line-height: 2;
  display: flex;
  display: -ms-flexbox;
  &-container {
    display: flex;
    display: -ms-flexbox;
    flex: 1 auto;
    flex-direction: column;
    max-width: 100%;
    position: relative;
    min-height: 100vh;
  }
}

.header-intro-background {
  background: no-repeat center url("../img/header/Light.gif");
  background-size: cover;
  background-position-y: top;
  // background-size: auto 860px;
}

.header-coin-background {
  background: no-repeat center url("../img/header/coin_bg.png");
  background-size: cover;
}

.header-board-background {
  background: no-repeat center url("../img/main/board/board_bg.png");
  background-size: cover;
}

/*=============================================
=            모바일            =
=============================================*/
@media screen and (max-width: 1439px) {
  html {
    min-width: 380px;
  }
  .desk {
    display: none !important;
  }

  .container {
    max-width: 380px;
    min-height: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }

  .non-height-container {
    max-width: 380px;
    margin: 0 auto;
    padding: 0 20px;
  }
  #header {
    max-width: 100%;
    .navigation {
      line-height: 60px;
      &-container {
        .navbar {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .dropdown {
          line-height: 26px;
          .select-language {
            color: #ffffff !important;
            border: 2px solid #fff;
            border-radius: 10px;
            padding: 0 15px;
            a {
              color: #fff !important;
            }
            .ant-dropdown-link {
              font-size: 10px;
              margin-right: 40px;
            }
            .anticon.anticon-down {
              margin: 0px;
            }
          }
        }
        .drawer {
          .ant-menu-inline {
            border-right: 0;
          }
          .ant-menu-item-selected {
            background-color: transparent;
          }
          .ant-menu-inline .ant-menu-item::after {
            border-right: 0px;
          }
        }
      }
    }
    .header-intro {
      padding-top: 10px;
      min-height: 630px;
      section {
        margin: 30px 0;
        .title {
          margin-bottom: 30px;
        }
        p {
          font-family: "Poppins";
          font-size: 12px;
        }
        .more-btn {
          width: 111px;
          height: 30px;
          background-color: aquamarine;
          border-radius: 30px;
          margin: 10px auto;
          span {
            font-size: 13px;
            color: #000;
            letter-spacing: 1.56px;
          }
        }
      }
      &-icon-wrap {
        width: 100%;
        position: relative;
        img {
          position: absolute;
          top: 0;
          left: 8%;
        }
      }
    }
    .header-coin {
      height: 471px;
      &__title {
        margin-top: 86px;
        margin-bottom: 20px;
        p {
          font-size: 18px;
          font-weight: normal;
        }
      }
      &__body {
        line-height: 2;
        span {
          font-size: 12px;
        }
      }
    }
    .header-board {
      min-height: 147px;
      &__title {
        span {
          font-size: 22px;
        }
      }
      &__body {
        span {
          font-size: 12px;
        }
      }
    }
  }
  #content {
    section {
      .intro {
        padding-top: 115px;
        padding-bottom: 150px;
        &-wrap {
          display: flex;
          flex-direction: column;
          padding-top: 1em;
          border-top: 1px solid #d1dfee;
          border-bottom: 1px solid #d1dfee;
          &-list {
            &:nth-child(1) {
              border-bottom: 1px solid #d1dfee;
              margin-bottom: 1em;
            }
            li {
              list-style: none;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        &-body {
          .video {
            max-width: 100%;
            background: no-repeat center url("../img/common/intro_bg_m.png");
            background-size: cover;
            padding: 23px 13px 33px;
            margin-bottom: 40px;
            &-wrap {
              height: 311px;
              background-color: #171a43;
              border-radius: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              span {
                font-size: 24px;
              }
            }
          }
          .link {
            display: flex;
            flex-wrap: wrap;
            &-wrap {
              display: flex;
              margin-bottom: 15px;
              width: 49%;
              height: 51px;
              border-radius: 9px;
              box-shadow: 0 0 20px 0 rgba(148, 180, 216, 0.38);
              padding: 0 8px 0 12px;
              justify-content: space-between;
              align-items: center;
              .left {
                .img-wrap {
                  width: 27px;
                  img {
                    width: 20px;
                  }
                }
                .title {
                  display: inline-block;
                  color: #171a43;
                  font-size: 11px;
                  font-weight: 600;
                }
              }
              .right {
                img {
                  width: 28px * 0.8;
                }
              }
            }
          }
        }
      }
    }
    main {
      height: 100%;
      .title {
        font-size: 22px;
      }
      .article {
        color: #273f87;
      }
      .main__sub1 {
        background: no-repeat center url("../img/main/sub1/sub1_bg_m.png");
        background-size: cover;
        .sub1 {
          height: 1398px;
          padding-bottom: 80px;
          &-wrap {
            display: flex;
            flex: 1;
            -ms-flex: 1;
            flex-direction: column;
            .left {
              margin-top: 64px;
              margin-bottom: 30px;
              .article {
                font-size: 12px;
              }
            }
            .right {
              .article {
                font-size: 12px;
              }
            }
          }
        }
      }
      .main__sub2 {
        background: no-repeat center url("../img/main/sub2/sub2_bg.png");
        background-size: cover;
        min-height: 1389px;
        padding-top: 16px;
        padding-bottom: 40px;
        .blobs-service {
          margin-bottom: 60px;
          &-container {
            padding-top: 16px;
            .left {
              margin-bottom: 18.5px;
              img {
                max-width: 100%;
                height: auto;
              }
            }
            .right {
              .title {
                font-size: 22px;
                font-weight: bold;
              }
              .article {
                font-size: 12px;
                line-height: 22px;
              }
            }
          }
        }
        .blobs-coin {
          .title {
            font-size: 22px;
            font-weight: bold;
          }
          .article {
            font-size: 12px;
            line-height: 22px;
          }
          &-description {
            .table-body {
              border: 1px solid #203060;
              padding: 35px 20px 24px;
              margin-bottom: 10px;
              background-color: #131336;
              img {
                width: 30%;
                height: auto;
              }
              &-title {
                font-size: 17px;
                font-weight: bold;
                margin-top: 22px;
                margin-bottom: 20px;
              }
              &-article {
                width: 100%;
                font-size: 12px;
                line-height: 26px;
              }
            }
          }
        }
      }
      .main__sub3 {
        background: no-repeat center url("../img/main//sub3/sub3_bg.png");
        background-size: cover;
        height: 603px;
        .sub3 {
          width: 100%;
          &-title {
            text-align: center;
            margin: 60px 0;
            span {
              font-size: 22px;
            }
          }
          .table {
            flex-direction: column;
            .article {
              font-size: 12px;
            }
            &-row {
              margin-bottom: 40px;
              &-title {
                text-align: center;
                margin-bottom: 20px;
              }
              &-article {
                span {
                  text-align: center;
                }
              }
            }
          }
        }
      }
      .sub4 {
        @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
          /* IE10+ CSS styles go here */
          height: 4300px;
        }
        .section1 {
          width: 380px;
          margin: 0 auto;
          &-title {
            text-align: center;
            margin-bottom: 30px;
          }
          &-content {
            padding: 0 20px;
            text-align: center;
            .article {
              font-size: 12px;
              line-height: 20px;
            }
          }
          &-img-wrap {
            .chart-wrap {
              width: 302px;
              padding: 34px 21px;
              border-bottom: 1px solid #8aa1e1;
            }
            .chart-labels {
              width: 302px;
              padding: 20px;
              flex-wrap: wrap;
              justify-content: space-between;
              .label-wrap {
                width: 35%;
                margin-bottom: 10px;
                .color {
                  width: 10px;
                  height: 10px;
                  border-radius: 8px;
                  background-color: #4346d3;
                  margin-right: 8px;
                }
                span {
                  font-size: 12px;
                }
              }
            }
          }
        }
        .section2 {
          // background: no-repeat center url("../img/main/sub4/bg.png");
          &-title {
            margin-bottom: 30px;
          }
          .roadmap {
            display: flex;
            height: 100%;
            .left {
              display: flex;
              margin-right: 15px;
              padding-top: 10px;
              img {
                height: 570px;
              }
            }
            .right {
              display: flex;
              flex-direction: column;
              line-height: 30px;
              flex: 1;
              span {
                font-size: 14px;
              }
              .road {
                display: flex;
                .date {
                  display: inline-block;
                  width: 20%;
                }
              }
            }
          }
        }
        .section3 {
          &-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            .member {
              width: 100%;
              min-height: 210px;
              border-radius: 20px;
              box-shadow: 0 0 40px 0 rgba(209, 223, 246, 0.31);
              background-color: #ffffff;
              margin-bottom: 27px;
              padding: 10px 14px 21px;
              &-name {
                border-bottom: 1px solid #253b7e;
                margin-bottom: 10px;
                .img-wrap {
                  width: 35%;
                  height: 56px;
                  position: relative;

                  img {
                    position: absolute;
                    bottom: -20px;
                    right: -20px;
                  }
                }
                span {
                  font-size: 16px;
                  color: #1e3885;
                  font-weight: bold;
                }
              }
              &-description {
                span {
                  font-size: 12px;
                  line-height: 20px;
                  color: #737a8f;
                }
              }
            }
            .member2 {
              width: 48%;
              min-height: 210px;
              border-radius: 20px;
              box-shadow: 0 0 40px 0 rgba(209, 223, 246, 0.31);
              background-color: #ffffff;
              margin-bottom: 27px;
              padding: 10px 14px 21px;
              &-name {
                border-bottom: 1px solid #253b7e;
                margin-bottom: 10px;
                .img-wrap {
                  width: 50%;
                  height: 56px;
                  position: relative;

                  img {
                    position: absolute;
                    bottom: -20px;
                    right: -20px;
                  }
                }
                span {
                  font-size: 16px;
                  color: #1e3885;
                  font-weight: bold;
                }
              }
              &-description {
                span {
                  font-size: 12px;
                  line-height: 20px;
                  color: #737a8f;
                }
              }
            }
            .advisor {
              width: 100%;
              min-height: 210px;
              border-radius: 20px;
              box-shadow: 0 0 40px 0 rgba(209, 223, 246, 0.31);
              background-color: #ffffff;
              margin-bottom: 27px;
              padding: 10px 14px 21px;
              &-name {
                border-bottom: 1px solid #253b7e;
                margin-bottom: 10px;
                .img-wrap {
                  width: 48%;
                  height: 56px;
                  position: relative;

                  img {
                    position: absolute;
                    bottom: -20px;
                    right: -20px;
                  }
                }
                span {
                  font-size: 16px;
                  color: #1e3885;
                  font-weight: bold;
                }
              }
              &-description {
                span {
                  font-size: 12px;
                  line-height: 20px;
                  color: #737a8f;
                }
              }
            }
          }
        }
      }
      .main__sub5 {
        background-image: linear-gradient(to bottom, #253b7e, #283e81);
        min-height: 821px;
        @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
          /* IE10+ CSS styles go here */
          margin-bottom: 170px;
        }
        .sub5 {
          &-title {
            text-align: center;
          }
          .content {
            .img-wrap {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .img-wrap:nth-child(1) {
              margin-bottom: -80px;
            }
            .img-wrap:nth-child(2) {
              margin-bottom: -80px;
            }
          }
        }
      }
    }
    .board {
      padding: 23px 0;
      &-item {
        border-radius: 11px;
        border: 1px solid #000000;
        margin-bottom: 15px;
        width: 100%;
      }
      .question {
        padding: 12.5px 0;
        &-wrap {
          margin: 0 14.8px;
          .icon {
            border-right: 1px solid #000;
            padding-right: 11.5px;
            margin-right: 11.5px;
          }
          .article {
            list-style: none;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            span {
              font-size: 14px;
            }
          }
        }
      }
      .answer {
        border-top: 1px solid #000;
        padding-left: 4.5%;
        padding-right: 18px;
        padding-top: 17px;
        padding-bottom: 20px;
        span {
          line-height: 2.14;
          font-size: 12px;
        }
      }
    }
  }
  #footer {
    background-color: #2a2a2a;
    min-height: 400px;
    span {
      color: #fff;
    }
    .footer-container {
      width: 100%;
      min-height: 380px;

      padding-top: 39px;
      padding-bottom: 45px;
      display: flex;
      flex-direction: column;
      .company-info {
        padding-bottom: 45px;
        .blobs-coin-title {
          color: #4d5261;
          font-size: 16px;
        }
        span {
          font-size: 12px;
        }
      }
      .right {
        .link-img {
          width: 32px;
        }
        .iso-img {
          width: 32px;
        }
        .association-img {
          width: 116px;
        }
      }
    }
  }
}

/*=============================================
=            데스크탑            =
=============================================*/

@media screen and (min-width: 1440px) {
  html {
    min-width: 1440px;
  }
  .mobile {
    display: none !important;
  }

  .container {
    max-width: 1340px;
    height: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }

  .non-height-container {
    max-width: 1340px;
    margin: 0 auto;
    padding: 0 20px;
  }
  #header {
    max-width: 100%;
    .navigation {
      // position: fixed;
      // z-index: 3;
      line-height: 84px;
      &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &-list {
        .ant-menu-item-active,
        .ant-menu-item-selected,
        .ant-menu-submenu-open,
        .ant-menu-submenu-selected,
        .ant-menu-submenu-title:hover {
          color: #3afdfb !important;
          border-bottom-color: #3afdfb;
        }
        .ant-menu-item {
          a {
            color: #fff;
          }
          a:hover {
            color: #3afdfb;
          }
        }
        .select-language {
          color: #ffffff !important;
          border: 2px solid #fff;
          border-radius: 10px;
          a {
            color: #fff !important;
          }
          .ant-dropdown-link {
            margin-right: 40px;
          }
          .anticon.anticon-down {
            margin: 0px;
          }
        }
        li {
          margin-left: 50px;
        }

        &-item {
          text-align: center;
          margin-left: 20px;
          list-style: none;
        }
        &-item a {
          color: white;
          text-decoration: none;
          padding-bottom: 5px;
        }
        &-item :hover {
          border-bottom: 1px solid rgba($color: #3afdfb, $alpha: 0.4);
        }
      }
    }
    .header-intro {
      // padding-top: 84px;
      min-height: 860px;
      section {
        margin: 30px 0;
        .title {
          margin-bottom: 30px;
        }
        p {
          font-size: 18px;
        }
        .more-btn {
          width: 185px;
          height: 50px;
          background-color: aquamarine;
          border-radius: 30px;
          margin: 10px auto;
          span {
            font-size: 22px;
            color: #000;
            letter-spacing: 2.64px;
          }
        }
      }
      &-icon-wrap {
        position: relative;
        img {
          position: absolute;
          left: 38.5%;
        }
      }
    }
    .header-coin {
      min-height: 800px;
      &__title {
        margin-top: 100px;
        margin-bottom: 130px;
        p {
          font-size: 54px;
          font-weight: normal;
        }
      }
      &__body {
        width: 1000px;
        line-height: 2;
        span {
          font-size: 18px;
        }
      }
    }
    .header-board {
      min-height: 367px;
      &__title {
        span {
          font-size: 36px;
        }
      }
    }
  }
  #content {
    section {
      .intro {
        padding-top: 180px;
        padding-bottom: 150px;
        &-wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &-list {
            li {
              list-style: none;
            }
          }
        }
        &-body {
          background: no-repeat url("../img/common/intro_bg.png");
          .video {
            margin-bottom: 100px;
            &-wrap {
              max-width: 100%;
              height: 722px;
              background-color: #171a43;
              border-radius: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              span {
                font-size: 36px;
              }
            }
          }
          .link {
            display: flex;
            flex-wrap: wrap;
            &-wrap {
              display: flex;
              margin-bottom: 20px;
              width: 30%;
              height: 125px;
              border-radius: 19px;
              box-shadow: 0 0 20px 0 rgba(148, 180, 216, 0.38);
              padding: 0 20px 0 30px;
              justify-content: space-between;
              align-items: center;
              .left {
                .img-wrap {
                  width: 80px;
                }
                .title {
                  display: inline-block;
                  color: #171a43;
                  font-size: 18px;
                  font-weight: 600;
                }
              }
              .right {
                img {
                  width: 69px * 0.8;
                }
              }
            }
          }
        }
      }
    }
    main {
      height: 100%;
      .title {
        font-size: 36px;
      }
      .article {
        color: #273f87;
        font-size: 18px;
      }
      .main__sub1 {
        height: 1360px;
        background: no-repeat center url("../img/main/sub1/sub1_bg.png");
        .sub1 {
          height: 100%;
          &-wrap {
            display: flex;
            flex: 1;
            justify-content: space-between;
            .left {
              flex: 0.45;
              position: relative;
              .img-wrap {
                img {
                  position: absolute;
                  top: 10%;
                  left: -100px;
                }
              }
            }
            .right {
              flex: 0.48;
            }
          }
        }
      }
      .main__sub2 {
        background: no-repeat center url("../img/main/sub2/sub2_bg.png");
        background-size: cover;
        padding-bottom: 56px;
        min-height: 1893px;
        .sub2 {
          .blobs-service {
            &-container {
              display: flex;
              justify-content: space-between;
              height: 722px;
              .left {
                position: relative;
                display: flex;
                flex: 0.47;
                img {
                  position: absolute;
                  top: 0;
                  right: 0;
                }
              }
              .right {
                flex: 0.5;
                .title {
                  font-size: 36px;
                  font-weight: bold;
                }
                .article {
                  line-height: 26px;
                }
              }
            }
          }
          .blobs-coin {
            .title {
              font-size: 36px;
              font-weight: bold;
            }
            &-description {
              display: flex;
              flex-wrap: wrap;
              border: 0.5px solid #203060;
              .table-body {
                width: 50%;
                border: 0.5px solid #203060;
                padding: 72px 101px 28px;
                background-color: #131336;
                &-title {
                  font-size: 36px;
                  font-weight: bold;
                  margin-top: 52px;
                  margin-bottom: 40px;
                }
                &-article {
                  width: 100%;
                  span {
                    overflow: hidden;
                    word-break: break-all;
                  }
                }
              }
            }
          }
        }
      }
      .main__sub3 {
        background: no-repeat center url("../img/main/sub3/sub3_bg.png");
        background-size: cover;
        min-height: 654px;
        .sub3 {
          width: 100%;
          &-title {
            text-align: center;
            margin-top: 60px;
            span {
              font-size: 36px;
            }
          }
          .table {
            flex-direction: column;
            &-row {
              width: 100%;
              padding: 75px 0;
              display: flex;
              align-items: center;
              &-title {
                flex: 0.25;
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                  font-size: 36px;
                  font-weight: bold;
                }
              }
              &-article {
                flex: 0.75;
                span {
                  font-size: 18px;
                  line-height: 26px;
                }
              }
            }
          }
        }
      }
      .sub4 {
        min-height: 3544px;
        .section1 {
          width: 774px;
          min-height: 860px;
          margin: 0 auto;
          &-title {
            text-align: center;
            margin-bottom: 60px;
          }
          &-content {
            text-align: center;
          }
          &-img-wrap {
            .chart-wrap {
              width: 426px;
              padding: 53px 30px;
              border-bottom: 1px solid #8aa1e1;
            }
            .chart-labels {
              width: 426px;
              padding: 20px;
              justify-content: space-around;
              .label-wrap {
                .color {
                  width: 10px;
                  height: 10px;
                  border-radius: 8px;
                  background-color: #4346d3;
                  margin-right: 8px;
                }

                span {
                  font-size: 12px;
                }
              }
            }
          }
        }
        .section2 {
          background: no-repeat center url("../img/main/sub4/bg.png");
          .roadmap {
            height: 1193px;
            border-radius: 33px;
            box-shadow: 0 0 40px 0 #ecf3fc;
            background-color: #fff;
            padding: 167px 52px 89px 150px;
            img {
              padding: 0 83px;
            }
            .left {
              flex: 0.36;
              min-height: 749px;
              span {
              }
            }
            .right {
              max-width: 40%;
            }
            @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
              /* IE10+ CSS styles go here */
              .left {
                width: 36%;
                .road {
                  margin-bottom: 60px;
                }
              }
              .right {
                width: 40%;
                text-align: justify;
                .road {
                  margin-bottom: 50px;
                }
              }
            }
            .right {
              flex: 0.6;
              min-height: 909px;
              .road {
                .date {
                  display: inline-block;
                  width: 20%;
                }
              }
            }
          }
        }
        .section3 {
          @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
            height: 3500px;
          }
          &-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            .member {
              width: 32%;
              min-height: 489px;
              border-radius: 20px;
              box-shadow: 0 0 40px 0 rgba(209, 223, 246, 0.31);
              background-color: #ffffff;
              margin-bottom: 100px;
              padding: 40px;
              &-name {
                border-bottom: 1px solid #253b7e;
                margin-bottom: 28.5px;
                .img-wrap {
                  position: relative;
                  width: 163px;
                  height: 163px;
                  img {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                  }
                }
                span {
                  font-size: 36px;
                  color: #1e3885;
                }
              }
              &-description {
                line-height: 2.18;
                color: #737a8f;
              }
            }
            .member2 {
              width: 32%;
              min-height: 489px;
              border-radius: 20px;
              box-shadow: 0 0 40px 0 rgba(209, 223, 246, 0.31);
              background-color: #ffffff;
              margin-bottom: 100px;
              padding: 40px;
              &-name {
                border-bottom: 1px solid #253b7e;
                margin-bottom: 28.5px;
                .img-wrap {
                  position: relative;
                  width: 163px;
                  height: 163px;
                  img {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                  }
                }
                span {
                  font-size: 36px;
                  color: #1e3885;
                }
              }
              &-description {
                line-height: 2.18;
                color: #737a8f;
              }
            }
            .advisor {
              width: 32%;
              min-height: 489px;
              border-radius: 20px;
              box-shadow: 0 0 40px 0 rgba(209, 223, 246, 0.31);
              background-color: #ffffff;
              margin-bottom: 100px;
              padding: 40px;
              &-name {
                border-bottom: 1px solid #253b7e;
                margin-bottom: 28.5px;
                .img-wrap {
                  position: relative;
                  width: 163px;
                  height: 163px;
                  img {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                  }
                }
                span {
                  font-size: 36px;
                  color: #1e3885;
                }
              }
              &-description {
                line-height: 2.18;
                color: #737a8f;
              }
            }
          }
        }
      }
      .main__sub5 {
        background-image: linear-gradient(to bottom, #253b7e, #283e81);
        flex: 1;
        min-height: 535px;
        .sub5 {
          &-title {
            text-align: center;
          }
          .content {
            display: flex;
            justify-content: center;
            .img-wrap {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    .board {
      padding: 90px 0;
      &-item {
        border-radius: 11px;
        border: 1px solid #000000;
        margin-bottom: 15px;
        width: 100%;
        .question {
          padding: 12.5px 0;
          &-wrap {
            margin-left: 23px;
            margin-right: 16px;
            .icon {
              width: 3%;
              border-right: 1px solid #000;
              margin-right: 16.5px;
            }
          }
        }
        .answer {
          border-top: 1px solid #000;
          padding-left: 4.5%;
          padding-right: 50px;
          padding-top: 30px;
          padding-bottom: 20px;
          span {
            line-height: 2.14;
          }
        }
      }
    }
  }
  #footer {
    background-color: #2a2a2a;
    span {
      color: #fff;
    }
    .footer-container {
      width: 100%;
      min-height: 380px;
      padding-top: 68px;
      display: flex;
      justify-content: space-between;
      .company-info {
        flex: 0.7;
        .blobs-coin-title {
          color: #4d5261;
          font-size: 18px;
        }
      }

      .right {
        flex: 0.3;
      }
    }
  }
}
