@charset "UTF-8";
@import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css);
@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);
@import url(https://fonts.googleapis.com/earlyaccess/notosanssc.css);
.app {
  line-height: 2;
  display: flex;
  display: -ms-flexbox; }
  .app-container {
    display: flex;
    display: -ms-flexbox;
    flex: 1 auto;
    flex-direction: column;
    max-width: 100%;
    position: relative;
    min-height: 100vh; }

.header-intro-background {
  background: no-repeat center url("../img/header/Light.gif");
  background-size: cover;
  background-position-y: top; }

.header-coin-background {
  background: no-repeat center url("../img/header/coin_bg.png");
  background-size: cover; }

.header-board-background {
  background: no-repeat center url("../img/main/board/board_bg.png");
  background-size: cover; }

/*=============================================
=            모바일            =
=============================================*/
@media screen and (max-width: 1439px) {
  html {
    min-width: 380px; }
  .desk {
    display: none !important; }
  .container {
    max-width: 380px;
    min-height: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 20px; }
  .non-height-container {
    max-width: 380px;
    margin: 0 auto;
    padding: 0 20px; }
  #header {
    max-width: 100%; }
    #header .navigation {
      line-height: 60px; }
      #header .navigation-container .navbar {
        display: flex;
        align-items: center;
        justify-content: space-between; }
      #header .navigation-container .dropdown {
        line-height: 26px; }
        #header .navigation-container .dropdown .select-language {
          color: #ffffff !important;
          border: 2px solid #fff;
          border-radius: 10px;
          padding: 0 15px; }
          #header .navigation-container .dropdown .select-language a {
            color: #fff !important; }
          #header .navigation-container .dropdown .select-language .ant-dropdown-link {
            font-size: 10px;
            margin-right: 40px; }
          #header .navigation-container .dropdown .select-language .anticon.anticon-down {
            margin: 0px; }
      #header .navigation-container .drawer .ant-menu-inline {
        border-right: 0; }
      #header .navigation-container .drawer .ant-menu-item-selected {
        background-color: transparent; }
      #header .navigation-container .drawer .ant-menu-inline .ant-menu-item::after {
        border-right: 0px; }
    #header .header-intro {
      padding-top: 10px;
      min-height: 630px; }
      #header .header-intro section {
        margin: 30px 0; }
        #header .header-intro section .title {
          margin-bottom: 30px; }
        #header .header-intro section p {
          font-family: "Poppins";
          font-size: 12px; }
        #header .header-intro section .more-btn {
          width: 111px;
          height: 30px;
          background-color: aquamarine;
          border-radius: 30px;
          margin: 10px auto; }
          #header .header-intro section .more-btn span {
            font-size: 13px;
            color: #000;
            letter-spacing: 1.56px; }
      #header .header-intro-icon-wrap {
        width: 100%;
        position: relative; }
        #header .header-intro-icon-wrap img {
          position: absolute;
          top: 0;
          left: 8%; }
    #header .header-coin {
      height: 471px; }
      #header .header-coin__title {
        margin-top: 86px;
        margin-bottom: 20px; }
        #header .header-coin__title p {
          font-size: 18px;
          font-weight: normal; }
      #header .header-coin__body {
        line-height: 2; }
        #header .header-coin__body span {
          font-size: 12px; }
    #header .header-board {
      min-height: 147px; }
      #header .header-board__title span {
        font-size: 22px; }
      #header .header-board__body span {
        font-size: 12px; }
  #content section .intro {
    padding-top: 115px;
    padding-bottom: 150px; }
    #content section .intro-wrap {
      display: flex;
      flex-direction: column;
      padding-top: 1em;
      border-top: 1px solid #d1dfee;
      border-bottom: 1px solid #d1dfee; }
      #content section .intro-wrap-list:nth-child(1) {
        border-bottom: 1px solid #d1dfee;
        margin-bottom: 1em; }
      #content section .intro-wrap-list li {
        list-style: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
    #content section .intro-body .video {
      max-width: 100%;
      background: no-repeat center url("../img/common/intro_bg_m.png");
      background-size: cover;
      padding: 23px 13px 33px;
      margin-bottom: 40px; }
      #content section .intro-body .video-wrap {
        height: 311px;
        background-color: #171a43;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center; }
        #content section .intro-body .video-wrap span {
          font-size: 24px; }
    #content section .intro-body .link {
      display: flex;
      flex-wrap: wrap; }
      #content section .intro-body .link-wrap {
        display: flex;
        margin-bottom: 15px;
        width: 49%;
        height: 51px;
        border-radius: 9px;
        box-shadow: 0 0 20px 0 rgba(148, 180, 216, 0.38);
        padding: 0 8px 0 12px;
        justify-content: space-between;
        align-items: center; }
        #content section .intro-body .link-wrap .left .img-wrap {
          width: 27px; }
          #content section .intro-body .link-wrap .left .img-wrap img {
            width: 20px; }
        #content section .intro-body .link-wrap .left .title {
          display: inline-block;
          color: #171a43;
          font-size: 11px;
          font-weight: 600; }
        #content section .intro-body .link-wrap .right img {
          width: 22.4px; }
  #content main {
    height: 100%; }
    #content main .title {
      font-size: 22px; }
    #content main .article {
      color: #273f87; }
    #content main .main__sub1 {
      background: no-repeat center url("../img/main/sub1/sub1_bg_m.png");
      background-size: cover; }
      #content main .main__sub1 .sub1 {
        height: 1398px;
        padding-bottom: 80px; }
        #content main .main__sub1 .sub1-wrap {
          display: flex;
          flex: 1;
          -ms-flex: 1;
          flex-direction: column; }
          #content main .main__sub1 .sub1-wrap .left {
            margin-top: 64px;
            margin-bottom: 30px; }
            #content main .main__sub1 .sub1-wrap .left .article {
              font-size: 12px; }
          #content main .main__sub1 .sub1-wrap .right .article {
            font-size: 12px; }
    #content main .main__sub2 {
      background: no-repeat center url("../img/main/sub2/sub2_bg.png");
      background-size: cover;
      min-height: 1389px;
      padding-top: 16px;
      padding-bottom: 40px; }
      #content main .main__sub2 .blobs-service {
        margin-bottom: 60px; }
        #content main .main__sub2 .blobs-service-container {
          padding-top: 16px; }
          #content main .main__sub2 .blobs-service-container .left {
            margin-bottom: 18.5px; }
            #content main .main__sub2 .blobs-service-container .left img {
              max-width: 100%;
              height: auto; }
          #content main .main__sub2 .blobs-service-container .right .title {
            font-size: 22px;
            font-weight: bold; }
          #content main .main__sub2 .blobs-service-container .right .article {
            font-size: 12px;
            line-height: 22px; }
      #content main .main__sub2 .blobs-coin .title {
        font-size: 22px;
        font-weight: bold; }
      #content main .main__sub2 .blobs-coin .article {
        font-size: 12px;
        line-height: 22px; }
      #content main .main__sub2 .blobs-coin-description .table-body {
        border: 1px solid #203060;
        padding: 35px 20px 24px;
        margin-bottom: 10px;
        background-color: #131336; }
        #content main .main__sub2 .blobs-coin-description .table-body img {
          width: 30%;
          height: auto; }
        #content main .main__sub2 .blobs-coin-description .table-body-title {
          font-size: 17px;
          font-weight: bold;
          margin-top: 22px;
          margin-bottom: 20px; }
        #content main .main__sub2 .blobs-coin-description .table-body-article {
          width: 100%;
          font-size: 12px;
          line-height: 26px; }
    #content main .main__sub3 {
      background: no-repeat center url("../img/main//sub3/sub3_bg.png");
      background-size: cover;
      height: 603px; }
      #content main .main__sub3 .sub3 {
        width: 100%; }
        #content main .main__sub3 .sub3-title {
          text-align: center;
          margin: 60px 0; }
          #content main .main__sub3 .sub3-title span {
            font-size: 22px; }
        #content main .main__sub3 .sub3 .table {
          flex-direction: column; }
          #content main .main__sub3 .sub3 .table .article {
            font-size: 12px; }
          #content main .main__sub3 .sub3 .table-row {
            margin-bottom: 40px; }
            #content main .main__sub3 .sub3 .table-row-title {
              text-align: center;
              margin-bottom: 20px; }
            #content main .main__sub3 .sub3 .table-row-article span {
              text-align: center; } }
  @media screen and (max-width: 1439px) and (-ms-high-contrast: none), screen and (max-width: 1439px) and (-ms-high-contrast: active) {
    #content main .sub4 {
      /* IE10+ CSS styles go here */
      height: 4300px; } }

@media screen and (max-width: 1439px) {
    #content main .sub4 .section1 {
      width: 380px;
      margin: 0 auto; }
      #content main .sub4 .section1-title {
        text-align: center;
        margin-bottom: 30px; }
      #content main .sub4 .section1-content {
        padding: 0 20px;
        text-align: center; }
        #content main .sub4 .section1-content .article {
          font-size: 12px;
          line-height: 20px; }
      #content main .sub4 .section1-img-wrap .chart-wrap {
        width: 302px;
        padding: 34px 21px;
        border-bottom: 1px solid #8aa1e1; }
      #content main .sub4 .section1-img-wrap .chart-labels {
        width: 302px;
        padding: 20px;
        flex-wrap: wrap;
        justify-content: space-between; }
        #content main .sub4 .section1-img-wrap .chart-labels .label-wrap {
          width: 35%;
          margin-bottom: 10px; }
          #content main .sub4 .section1-img-wrap .chart-labels .label-wrap .color {
            width: 10px;
            height: 10px;
            border-radius: 8px;
            background-color: #4346d3;
            margin-right: 8px; }
          #content main .sub4 .section1-img-wrap .chart-labels .label-wrap span {
            font-size: 12px; }
    #content main .sub4 .section2-title {
      margin-bottom: 30px; }
    #content main .sub4 .section2 .roadmap {
      display: flex;
      height: 100%; }
      #content main .sub4 .section2 .roadmap .left {
        display: flex;
        margin-right: 15px;
        padding-top: 10px; }
        #content main .sub4 .section2 .roadmap .left img {
          height: 570px; }
      #content main .sub4 .section2 .roadmap .right {
        display: flex;
        flex-direction: column;
        line-height: 30px;
        flex: 1; }
        #content main .sub4 .section2 .roadmap .right span {
          font-size: 14px; }
        #content main .sub4 .section2 .roadmap .right .road {
          display: flex; }
          #content main .sub4 .section2 .roadmap .right .road .date {
            display: inline-block;
            width: 20%; }
    #content main .sub4 .section3-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; }
      #content main .sub4 .section3-content .member {
        width: 100%;
        min-height: 210px;
        border-radius: 20px;
        box-shadow: 0 0 40px 0 rgba(209, 223, 246, 0.31);
        background-color: #ffffff;
        margin-bottom: 27px;
        padding: 10px 14px 21px; }
        #content main .sub4 .section3-content .member-name {
          border-bottom: 1px solid #253b7e;
          margin-bottom: 10px; }
          #content main .sub4 .section3-content .member-name .img-wrap {
            width: 35%;
            height: 56px;
            position: relative; }
            #content main .sub4 .section3-content .member-name .img-wrap img {
              position: absolute;
              bottom: -20px;
              right: -20px; }
          #content main .sub4 .section3-content .member-name span {
            font-size: 16px;
            color: #1e3885;
            font-weight: bold; }
        #content main .sub4 .section3-content .member-description span {
          font-size: 12px;
          line-height: 20px;
          color: #737a8f; }
      #content main .sub4 .section3-content .member2 {
        width: 48%;
        min-height: 210px;
        border-radius: 20px;
        box-shadow: 0 0 40px 0 rgba(209, 223, 246, 0.31);
        background-color: #ffffff;
        margin-bottom: 27px;
        padding: 10px 14px 21px; }
        #content main .sub4 .section3-content .member2-name {
          border-bottom: 1px solid #253b7e;
          margin-bottom: 10px; }
          #content main .sub4 .section3-content .member2-name .img-wrap {
            width: 50%;
            height: 56px;
            position: relative; }
            #content main .sub4 .section3-content .member2-name .img-wrap img {
              position: absolute;
              bottom: -20px;
              right: -20px; }
          #content main .sub4 .section3-content .member2-name span {
            font-size: 16px;
            color: #1e3885;
            font-weight: bold; }
        #content main .sub4 .section3-content .member2-description span {
          font-size: 12px;
          line-height: 20px;
          color: #737a8f; }
      #content main .sub4 .section3-content .advisor {
        width: 100%;
        min-height: 210px;
        border-radius: 20px;
        box-shadow: 0 0 40px 0 rgba(209, 223, 246, 0.31);
        background-color: #ffffff;
        margin-bottom: 27px;
        padding: 10px 14px 21px; }
        #content main .sub4 .section3-content .advisor-name {
          border-bottom: 1px solid #253b7e;
          margin-bottom: 10px; }
          #content main .sub4 .section3-content .advisor-name .img-wrap {
            width: 48%;
            height: 56px;
            position: relative; }
            #content main .sub4 .section3-content .advisor-name .img-wrap img {
              position: absolute;
              bottom: -20px;
              right: -20px; }
          #content main .sub4 .section3-content .advisor-name span {
            font-size: 16px;
            color: #1e3885;
            font-weight: bold; }
        #content main .sub4 .section3-content .advisor-description span {
          font-size: 12px;
          line-height: 20px;
          color: #737a8f; }
    #content main .main__sub5 {
      background-image: linear-gradient(to bottom, #253b7e, #283e81);
      min-height: 821px; } }
    @media screen and (max-width: 1439px) and (-ms-high-contrast: none), screen and (max-width: 1439px) and (-ms-high-contrast: active) {
      #content main .main__sub5 {
        /* IE10+ CSS styles go here */
        margin-bottom: 170px; } }

@media screen and (max-width: 1439px) {
      #content main .main__sub5 .sub5-title {
        text-align: center; }
      #content main .main__sub5 .sub5 .content .img-wrap {
        display: flex;
        justify-content: center;
        align-items: center; }
      #content main .main__sub5 .sub5 .content .img-wrap:nth-child(1) {
        margin-bottom: -80px; }
      #content main .main__sub5 .sub5 .content .img-wrap:nth-child(2) {
        margin-bottom: -80px; }
  #content .board {
    padding: 23px 0; }
    #content .board-item {
      border-radius: 11px;
      border: 1px solid #000000;
      margin-bottom: 15px;
      width: 100%; }
    #content .board .question {
      padding: 12.5px 0; }
      #content .board .question-wrap {
        margin: 0 14.8px; }
        #content .board .question-wrap .icon {
          border-right: 1px solid #000;
          padding-right: 11.5px;
          margin-right: 11.5px; }
        #content .board .question-wrap .article {
          list-style: none;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
          #content .board .question-wrap .article span {
            font-size: 14px; }
    #content .board .answer {
      border-top: 1px solid #000;
      padding-left: 4.5%;
      padding-right: 18px;
      padding-top: 17px;
      padding-bottom: 20px; }
      #content .board .answer span {
        line-height: 2.14;
        font-size: 12px; }
  #footer {
    background-color: #2a2a2a;
    min-height: 400px; }
    #footer span {
      color: #fff; }
    #footer .footer-container {
      width: 100%;
      min-height: 380px;
      padding-top: 39px;
      padding-bottom: 45px;
      display: flex;
      flex-direction: column; }
      #footer .footer-container .company-info {
        padding-bottom: 45px; }
        #footer .footer-container .company-info .blobs-coin-title {
          color: #4d5261;
          font-size: 16px; }
        #footer .footer-container .company-info span {
          font-size: 12px; }
      #footer .footer-container .right .link-img {
        width: 32px; }
      #footer .footer-container .right .iso-img {
        width: 32px; }
      #footer .footer-container .right .association-img {
        width: 116px; } }

/*=============================================
=            데스크탑            =
=============================================*/
@media screen and (min-width: 1440px) {
  html {
    min-width: 1440px; }
  .mobile {
    display: none !important; }
  .container {
    max-width: 1340px;
    height: 100%;
    margin: 0 auto;
    padding: 0 20px; }
  .non-height-container {
    max-width: 1340px;
    margin: 0 auto;
    padding: 0 20px; }
  #header {
    max-width: 100%; }
    #header .navigation {
      line-height: 84px; }
      #header .navigation-container {
        display: flex;
        align-items: center;
        justify-content: space-between; }
      #header .navigation-list .ant-menu-item-active,
      #header .navigation-list .ant-menu-item-selected,
      #header .navigation-list .ant-menu-submenu-open,
      #header .navigation-list .ant-menu-submenu-selected,
      #header .navigation-list .ant-menu-submenu-title:hover {
        color: #3afdfb !important;
        border-bottom-color: #3afdfb; }
      #header .navigation-list .ant-menu-item a {
        color: #fff; }
      #header .navigation-list .ant-menu-item a:hover {
        color: #3afdfb; }
      #header .navigation-list .select-language {
        color: #ffffff !important;
        border: 2px solid #fff;
        border-radius: 10px; }
        #header .navigation-list .select-language a {
          color: #fff !important; }
        #header .navigation-list .select-language .ant-dropdown-link {
          margin-right: 40px; }
        #header .navigation-list .select-language .anticon.anticon-down {
          margin: 0px; }
      #header .navigation-list li {
        margin-left: 50px; }
      #header .navigation-list-item {
        text-align: center;
        margin-left: 20px;
        list-style: none; }
      #header .navigation-list-item a {
        color: white;
        text-decoration: none;
        padding-bottom: 5px; }
      #header .navigation-list-item :hover {
        border-bottom: 1px solid rgba(58, 253, 251, 0.4); }
    #header .header-intro {
      min-height: 860px; }
      #header .header-intro section {
        margin: 30px 0; }
        #header .header-intro section .title {
          margin-bottom: 30px; }
        #header .header-intro section p {
          font-size: 18px; }
        #header .header-intro section .more-btn {
          width: 185px;
          height: 50px;
          background-color: aquamarine;
          border-radius: 30px;
          margin: 10px auto; }
          #header .header-intro section .more-btn span {
            font-size: 22px;
            color: #000;
            letter-spacing: 2.64px; }
      #header .header-intro-icon-wrap {
        position: relative; }
        #header .header-intro-icon-wrap img {
          position: absolute;
          left: 38.5%; }
    #header .header-coin {
      min-height: 800px; }
      #header .header-coin__title {
        margin-top: 100px;
        margin-bottom: 130px; }
        #header .header-coin__title p {
          font-size: 54px;
          font-weight: normal; }
      #header .header-coin__body {
        width: 1000px;
        line-height: 2; }
        #header .header-coin__body span {
          font-size: 18px; }
    #header .header-board {
      min-height: 367px; }
      #header .header-board__title span {
        font-size: 36px; }
  #content section .intro {
    padding-top: 180px;
    padding-bottom: 150px; }
    #content section .intro-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      #content section .intro-wrap-list li {
        list-style: none; }
    #content section .intro-body {
      background: no-repeat url("../img/common/intro_bg.png"); }
      #content section .intro-body .video {
        margin-bottom: 100px; }
        #content section .intro-body .video-wrap {
          max-width: 100%;
          height: 722px;
          background-color: #171a43;
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center; }
          #content section .intro-body .video-wrap span {
            font-size: 36px; }
      #content section .intro-body .link {
        display: flex;
        flex-wrap: wrap; }
        #content section .intro-body .link-wrap {
          display: flex;
          margin-bottom: 20px;
          width: 30%;
          height: 125px;
          border-radius: 19px;
          box-shadow: 0 0 20px 0 rgba(148, 180, 216, 0.38);
          padding: 0 20px 0 30px;
          justify-content: space-between;
          align-items: center; }
          #content section .intro-body .link-wrap .left .img-wrap {
            width: 80px; }
          #content section .intro-body .link-wrap .left .title {
            display: inline-block;
            color: #171a43;
            font-size: 18px;
            font-weight: 600; }
          #content section .intro-body .link-wrap .right img {
            width: 55.2px; }
  #content main {
    height: 100%; }
    #content main .title {
      font-size: 36px; }
    #content main .article {
      color: #273f87;
      font-size: 18px; }
    #content main .main__sub1 {
      height: 1360px;
      background: no-repeat center url("../img/main/sub1/sub1_bg.png"); }
      #content main .main__sub1 .sub1 {
        height: 100%; }
        #content main .main__sub1 .sub1-wrap {
          display: flex;
          flex: 1;
          justify-content: space-between; }
          #content main .main__sub1 .sub1-wrap .left {
            flex: 0.45;
            position: relative; }
            #content main .main__sub1 .sub1-wrap .left .img-wrap img {
              position: absolute;
              top: 10%;
              left: -100px; }
          #content main .main__sub1 .sub1-wrap .right {
            flex: 0.48; }
    #content main .main__sub2 {
      background: no-repeat center url("../img/main/sub2/sub2_bg.png");
      background-size: cover;
      padding-bottom: 56px;
      min-height: 1893px; }
      #content main .main__sub2 .sub2 .blobs-service-container {
        display: flex;
        justify-content: space-between;
        height: 722px; }
        #content main .main__sub2 .sub2 .blobs-service-container .left {
          position: relative;
          display: flex;
          flex: 0.47; }
          #content main .main__sub2 .sub2 .blobs-service-container .left img {
            position: absolute;
            top: 0;
            right: 0; }
        #content main .main__sub2 .sub2 .blobs-service-container .right {
          flex: 0.5; }
          #content main .main__sub2 .sub2 .blobs-service-container .right .title {
            font-size: 36px;
            font-weight: bold; }
          #content main .main__sub2 .sub2 .blobs-service-container .right .article {
            line-height: 26px; }
      #content main .main__sub2 .sub2 .blobs-coin .title {
        font-size: 36px;
        font-weight: bold; }
      #content main .main__sub2 .sub2 .blobs-coin-description {
        display: flex;
        flex-wrap: wrap;
        border: 0.5px solid #203060; }
        #content main .main__sub2 .sub2 .blobs-coin-description .table-body {
          width: 50%;
          border: 0.5px solid #203060;
          padding: 72px 101px 28px;
          background-color: #131336; }
          #content main .main__sub2 .sub2 .blobs-coin-description .table-body-title {
            font-size: 36px;
            font-weight: bold;
            margin-top: 52px;
            margin-bottom: 40px; }
          #content main .main__sub2 .sub2 .blobs-coin-description .table-body-article {
            width: 100%; }
            #content main .main__sub2 .sub2 .blobs-coin-description .table-body-article span {
              overflow: hidden;
              word-break: break-all; }
    #content main .main__sub3 {
      background: no-repeat center url("../img/main/sub3/sub3_bg.png");
      background-size: cover;
      min-height: 654px; }
      #content main .main__sub3 .sub3 {
        width: 100%; }
        #content main .main__sub3 .sub3-title {
          text-align: center;
          margin-top: 60px; }
          #content main .main__sub3 .sub3-title span {
            font-size: 36px; }
        #content main .main__sub3 .sub3 .table {
          flex-direction: column; }
          #content main .main__sub3 .sub3 .table-row {
            width: 100%;
            padding: 75px 0;
            display: flex;
            align-items: center; }
            #content main .main__sub3 .sub3 .table-row-title {
              flex: 0.25;
              display: flex;
              align-items: center;
              justify-content: center; }
              #content main .main__sub3 .sub3 .table-row-title span {
                font-size: 36px;
                font-weight: bold; }
            #content main .main__sub3 .sub3 .table-row-article {
              flex: 0.75; }
              #content main .main__sub3 .sub3 .table-row-article span {
                font-size: 18px;
                line-height: 26px; }
    #content main .sub4 {
      min-height: 3544px; }
      #content main .sub4 .section1 {
        width: 774px;
        min-height: 860px;
        margin: 0 auto; }
        #content main .sub4 .section1-title {
          text-align: center;
          margin-bottom: 60px; }
        #content main .sub4 .section1-content {
          text-align: center; }
        #content main .sub4 .section1-img-wrap .chart-wrap {
          width: 426px;
          padding: 53px 30px;
          border-bottom: 1px solid #8aa1e1; }
        #content main .sub4 .section1-img-wrap .chart-labels {
          width: 426px;
          padding: 20px;
          justify-content: space-around; }
          #content main .sub4 .section1-img-wrap .chart-labels .label-wrap .color {
            width: 10px;
            height: 10px;
            border-radius: 8px;
            background-color: #4346d3;
            margin-right: 8px; }
          #content main .sub4 .section1-img-wrap .chart-labels .label-wrap span {
            font-size: 12px; }
      #content main .sub4 .section2 {
        background: no-repeat center url("../img/main/sub4/bg.png"); }
        #content main .sub4 .section2 .roadmap {
          height: 1193px;
          border-radius: 33px;
          box-shadow: 0 0 40px 0 #ecf3fc;
          background-color: #fff;
          padding: 167px 52px 89px 150px; }
          #content main .sub4 .section2 .roadmap img {
            padding: 0 83px; }
          #content main .sub4 .section2 .roadmap .left {
            flex: 0.36;
            min-height: 749px; }
          #content main .sub4 .section2 .roadmap .right {
            max-width: 40%; } }
        @media screen and (min-width: 1440px) and (-ms-high-contrast: none), screen and (min-width: 1440px) and (-ms-high-contrast: active) {
          #content main .sub4 .section2 .roadmap {
            /* IE10+ CSS styles go here */ }
            #content main .sub4 .section2 .roadmap .left {
              width: 36%; }
              #content main .sub4 .section2 .roadmap .left .road {
                margin-bottom: 60px; }
            #content main .sub4 .section2 .roadmap .right {
              width: 40%;
              text-align: justify; }
              #content main .sub4 .section2 .roadmap .right .road {
                margin-bottom: 50px; } }

@media screen and (min-width: 1440px) {
          #content main .sub4 .section2 .roadmap .right {
            flex: 0.6;
            min-height: 909px; }
            #content main .sub4 .section2 .roadmap .right .road .date {
              display: inline-block;
              width: 20%; } }
    @media screen and (min-width: 1440px) and (-ms-high-contrast: none), screen and (min-width: 1440px) and (-ms-high-contrast: active) {
      #content main .sub4 .section3 {
        height: 3500px; } }

@media screen and (min-width: 1440px) {
      #content main .sub4 .section3-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center; }
        #content main .sub4 .section3-content .member {
          width: 32%;
          min-height: 489px;
          border-radius: 20px;
          box-shadow: 0 0 40px 0 rgba(209, 223, 246, 0.31);
          background-color: #ffffff;
          margin-bottom: 100px;
          padding: 40px; }
          #content main .sub4 .section3-content .member-name {
            border-bottom: 1px solid #253b7e;
            margin-bottom: 28.5px; }
            #content main .sub4 .section3-content .member-name .img-wrap {
              position: relative;
              width: 163px;
              height: 163px; }
              #content main .sub4 .section3-content .member-name .img-wrap img {
                position: absolute;
                bottom: 0;
                right: 0; }
            #content main .sub4 .section3-content .member-name span {
              font-size: 36px;
              color: #1e3885; }
          #content main .sub4 .section3-content .member-description {
            line-height: 2.18;
            color: #737a8f; }
        #content main .sub4 .section3-content .member2 {
          width: 32%;
          min-height: 489px;
          border-radius: 20px;
          box-shadow: 0 0 40px 0 rgba(209, 223, 246, 0.31);
          background-color: #ffffff;
          margin-bottom: 100px;
          padding: 40px; }
          #content main .sub4 .section3-content .member2-name {
            border-bottom: 1px solid #253b7e;
            margin-bottom: 28.5px; }
            #content main .sub4 .section3-content .member2-name .img-wrap {
              position: relative;
              width: 163px;
              height: 163px; }
              #content main .sub4 .section3-content .member2-name .img-wrap img {
                position: absolute;
                bottom: 0;
                right: 0; }
            #content main .sub4 .section3-content .member2-name span {
              font-size: 36px;
              color: #1e3885; }
          #content main .sub4 .section3-content .member2-description {
            line-height: 2.18;
            color: #737a8f; }
        #content main .sub4 .section3-content .advisor {
          width: 32%;
          min-height: 489px;
          border-radius: 20px;
          box-shadow: 0 0 40px 0 rgba(209, 223, 246, 0.31);
          background-color: #ffffff;
          margin-bottom: 100px;
          padding: 40px; }
          #content main .sub4 .section3-content .advisor-name {
            border-bottom: 1px solid #253b7e;
            margin-bottom: 28.5px; }
            #content main .sub4 .section3-content .advisor-name .img-wrap {
              position: relative;
              width: 163px;
              height: 163px; }
              #content main .sub4 .section3-content .advisor-name .img-wrap img {
                position: absolute;
                bottom: 0;
                right: 0; }
            #content main .sub4 .section3-content .advisor-name span {
              font-size: 36px;
              color: #1e3885; }
          #content main .sub4 .section3-content .advisor-description {
            line-height: 2.18;
            color: #737a8f; }
    #content main .main__sub5 {
      background-image: linear-gradient(to bottom, #253b7e, #283e81);
      flex: 1;
      min-height: 535px; }
      #content main .main__sub5 .sub5-title {
        text-align: center; }
      #content main .main__sub5 .sub5 .content {
        display: flex;
        justify-content: center; }
        #content main .main__sub5 .sub5 .content .img-wrap {
          display: flex;
          justify-content: center;
          align-items: center; }
  #content .board {
    padding: 90px 0; }
    #content .board-item {
      border-radius: 11px;
      border: 1px solid #000000;
      margin-bottom: 15px;
      width: 100%; }
      #content .board-item .question {
        padding: 12.5px 0; }
        #content .board-item .question-wrap {
          margin-left: 23px;
          margin-right: 16px; }
          #content .board-item .question-wrap .icon {
            width: 3%;
            border-right: 1px solid #000;
            margin-right: 16.5px; }
      #content .board-item .answer {
        border-top: 1px solid #000;
        padding-left: 4.5%;
        padding-right: 50px;
        padding-top: 30px;
        padding-bottom: 20px; }
        #content .board-item .answer span {
          line-height: 2.14; }
  #footer {
    background-color: #2a2a2a; }
    #footer span {
      color: #fff; }
    #footer .footer-container {
      width: 100%;
      min-height: 380px;
      padding-top: 68px;
      display: flex;
      justify-content: space-between; }
      #footer .footer-container .company-info {
        flex: 0.7; }
        #footer .footer-container .company-info .blobs-coin-title {
          color: #4d5261;
          font-size: 18px; }
      #footer .footer-container .right {
        flex: 0.3; } }

/*display*/
.flex-row {
  display: flex; }

.flex-column {
  flex-direction: column; }

.flex-center {
  align-items: center; }

.flex-sa {
  justify-content: space-around !important; }

.flex-sb {
  justify-content: space-between; }

.flex-end {
  justify-content: flex-end; }

.justify-center {
  justify-content: center; }

.flex-1 {
  flex: 1;
  -ms-flex: 1; }

.t-a-c {
  text-align: center; }

.white-text {
  color: #ffffff !important; }

.basic-text {
  color: #000000 !important; }

.font-20 {
  font-size: 20px !important; }

.font-24 {
  font-size: 24px !important; }

.font-36 {
  font-size: 36px !important; }

.font-48 {
  font-size: 48px !important; }

.font-50 {
  font-size: 50px !important; }

.font-bold {
  font-weight: 700; }

.m-x-2 {
  margin: 0 8px; }

.m-y-40 {
  margin: 40px 0px; }

.m-y-60 {
  margin: 60px 0px; }

.p-y-40 {
  padding-top: 40px;
  padding-bottom: 40px; }

.p-y-60 {
  padding-top: 60px;
  padding-bottom: 60px; }

.m-t-3 {
  margin-top: 16px; }

.m-t-60 {
  margin-top: 60px; }

.m-b-2 {
  margin-bottom: 8px; }

.m-b-3 {
  margin-bottom: 16px; }

.m-b-4 {
  margin-bottom: 24px; }

.m-b-5 {
  margin-bottom: 32px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-b-100 {
  margin-bottom: 100px; }

.m-r-4 {
  margin-right: 24px; }

.p-b-20 {
  padding-bottom: 20px; }

.user-select {
  user-select: none; }

.pointer {
  cursor: pointer; }

.responsive-img {
  max-width: 100%;
  height: auto; }

/*  한국어 */
:lang(ko) {
  font-family: "Noto Sans KR", sans-serif; }

/*  영어 */
:lang(en) {
  font-family: "Noto Sans KR", sans-serif; }

/* 중국어 (간체) */
:lang(cn) {
  font-family: "Noto Sans SC", sans-serif; }

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: auto;
  position: relative; }

* {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: sans-serif; }

*,
:after,
:before {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  word-break: keep-all; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

#root {
  height: 100%;
  display: flex;
  flex-direction: column; }

.App {
  text-align: center; }

.App-logo {
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #09d3ac; }
